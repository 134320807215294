<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Λίστα Τύπων Πληρωμών</h3>

                <div class="card-tools">
                  
                  <button type="button" class="btn btn-sm btn-primary text-light" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Προσθήκη Νέου Τύπου
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Τύπος</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                <tbody>
                     <tr v-for="paymentType in paymentTypes.data" :key="paymentType.id">

                      <td>{{paymentType.id}}</td>
                      <td>{{paymentType.name}}</td>
                      <td>
                        
                        <a href="#" @click="editModal(paymentType)">
                            <button class="bg-primary btn btn-sm" title="Επεξεργασία τρόπου πληρωμής">
                              <i class="fa fa-edit"></i>
                            </button>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="paymentTypes" @pagination-change-page="getPaymentTypes"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Δημιουργία Νέου Τρόπου Πληρωμής</h5>
                    <h5 class="modal-title" v-show="editmode">Επεξεργασία Τρόπου Πληρωμής</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="editmode ? updatePaymentType() : createPaymentType()">
                    <div class="modal-body">
                        <div class="form-group col-12 d-inline-block">
                            <v-text-field
                                label="Ονομασία"
                                v-model="form.name"
                            ></v-text-field>
                            <has-error :form="form" field="name"></has-error>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Κλείσιμο</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Ενημέρωση</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Δημουργία</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>

    export default {
        data () {
            return {
                editmode: false,
                paymentTypes : {},
                form: new Form({
                    id : '',
                    name: ''
                }),
                page: 1
            }
        },
        methods: {

          getPaymentTypes(page = 1) {

              //keep page to stay on the same page
              this.page = page;

              this.$Progress.start();
              
              axios.get('/api/paymentTypes?page=' + page).then(({ data }) => (this.paymentTypes = data.data));

              this.$Progress.finish();
          },
          loadPaymentTypes(){
              axios.get("/api/paymentTypes").then(({ data }) => (this.paymentTypes = data.data));
          },
          editModal(vehicle){
              this.editmode = true;
              this.form.reset();
              $('#addNew').modal('show');
              this.form.fill(vehicle);
          },
          newModal(){
              this.editmode = false;
              this.form.reset();
              $('#addNew').modal('show');
          },
          createPaymentType(){
              this.$Progress.start();

              this.form.post('/api/paymentTypes')
              .then((data)=>{
                if(data.data.success){
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadPaymentTypes();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
          updatePaymentType(){
              this.$Progress.start();
              this.form.put('/api/paymentTypes/'+this.form.id)
              .then((response) => {
                  // success
                  $('#addNew').modal('hide');
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();

                  
                  this.getPaymentTypes(this.page);
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },
        },
        mounted() {
            
        },
        created() {
            this.$Progress.start();

            this.loadPaymentTypes();

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
          },
        },
    }
</script>

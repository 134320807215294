<template>
  <section class="content">
    <div class="container-fluid" data-app>
        <div class="row">

          <div class="col-12">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Λίστα Ρόλων</h3>

                <div class="card-tools">
                  
                  <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Προσθήκη Νέου Ρόλου
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Ρόλος</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="paymentType in roles.data" :key="paymentType.id">

                      <td>{{paymentType.id}}</td>
                      <td>{{paymentType.name}}</td>
                      <td>
                        
                        <a href="#" @click="editModal(paymentType)">
                            <i class="fa fa-edit blue"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="roles" @pagination-change-page="getRoles"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Δημιουργία Νέου Ρόλου</h5>
                    <h5 class="modal-title" v-show="editmode">Επεξεργασία Ρόλου</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="editmode ? updateRole() : createRole()">
                    <div class="modal-body">
                        <div class="form-group col-12 d-inline-block">
                            <v-text-field
                                label="Ονομασία"
                                v-model="form.name"
                            ></v-text-field>
                            <has-error :form="form" field="name"></has-error>
                        </div>

                        <div class="form-group">
                            <v-select
                                dense
                                :chips="true"
                                :multiple="true"
                                v-model="form.permissions"
                                label="Προεπιλεγμένα δικαιώματα"
                                :items="permissions"
                                item-text="name"
                                item-value="id"
                            >
                            </v-select>
                        </div>


                    </div>




                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>

    export default {
        data () {
            return {
                editmode: false,
                roles : {},
                permissions : [],
                form: new Form({
                    id : '',
                    name: '',
                    permissions: []
                }),
                page: 1
            }
        },
        methods: {

          getRoles(page = 1) {

              //keep page to stay on the same page
              this.page = page;
              this.$Progress.start();
              axios.get('/api/roles?page=' + page).then(({ data }) => (this.roles = data.data));
              this.$Progress.finish();
          },
          loadroles(){
              axios.get("/api/roles").then(({ data }) => (this.roles = data.data));
          },
          loadPermissions(){
              axios.get("/api/roles/permissions").then(({ data }) => (this.permissions = data.data));
          },




          editModal(role){
              if(role.permissions != null && role.permissions.length > 0)
                  role.permissions = JSON.parse(role.permissions);

              this.editmode = true;
              this.form.reset();
              $('#addNew').modal('show');
              this.form.fill(role);
          },
          newModal(){
              this.editmode = false;
              this.form.reset();
              $('#addNew').modal('show');
          },
          createRole(){
              this.$Progress.start();

              this.form.post('/api/roles')
              .then((data)=>{
                if(data.data.success){
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadroles();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
          updateRole(){
              this.$Progress.start();
              this.form.put('/api/roles/'+this.form.id)
              .then((response) => {
                  // success
                  $('#addNew').modal('hide');
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();

                  
                  this.getRoles(this.page);
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },
        },
        mounted() {
            
        },
        created() {
            this.$Progress.start();

            this.loadroles();
            this.loadPermissions();

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
          },
        },
    }
</script>
